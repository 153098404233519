var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[(_vm.showPlayer)?[_c('v-expand-transition',[(_vm.showPlayer || (_vm.recordList.length > 0 && _vm.showPlayer))?_c('record-player',{attrs:{"file":_vm.file},on:{"closed":_vm.closedPlayer}}):_vm._e()],1),_c('v-divider')]:_vm._e(),(_vm.from != 'Lead')?[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{staticClass:"mx-2",attrs:{"label":"Search by Employees","items":_vm.workersf,"loading":_vm.loadingEmployees,"filter":function (item, queryText) {
              return item.fullname
                .toLowerCase()
                .includes(queryText.toLowerCase());
            },"item-text":"fullname","item-value":"uuid","autocomplete":"off","clearable":"","hide-details":"","outlined":"","dense":""},on:{"change":_vm.getRecords},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),(data.item.ocupation)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.ocupation.name)}}):_vm._e(),(data.item.phone)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.phone)}}):_vm._e()],1)]}}],null,false,1535484464),model:{value:(_vm.reqbody.employeeUuid),callback:function ($$v) {_vm.$set(_vm.reqbody, "employeeUuid", $$v)},expression:"reqbody.employeeUuid"}})],1),_c('v-spacer'),_c('v-col',{},[_c('ma-date-picker',{attrs:{"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"mx-2"},[_c('ma-date-picker',{attrs:{"label":"To","past":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),(_vm.validClean)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","elevation":"0","small":"","rounded":"","depressed":"","fab":""},on:{"click":_vm._clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,false,527521509)},[_c('span',[_vm._v("Clear filters")])])]:_vm._e()],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.recorded,"footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.totalrecord,"item-key":"uuid","loading":_vm.loadingR},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.duration",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.prettyTime(item.duration)))])]}},{key:"item.createAt",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.formatDate(item.createAt)))])]}},{key:"item.from",fn:function(ref){
            var item = ref.item;
return [(item.inbound == 1 || item.inbound == true)?[_vm._v(" "+_vm._s(item.to)+" ")]:[_vm._v(" "+_vm._s(item.from)+" ")]]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.from == "Lead" ? item.employee.fullname : item.employee_fullname)+" ")]}},{key:"item.origin",fn:function(ref){
            var item = ref.item;
return [(item.inbound == 1 || item.inbound == true)?[_vm._v(_vm._s(_vm.from))]:[_vm._v(" Employee ")]]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(/* Play record */)+" "),_c('div',{staticClass:"d-flex justify-end"},[(item.timeMarcInit && item.timeMarcEnd)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loadingaudio,"loading":_vm.loadingaudio && _vm.currentAudio == item && _vm.part,"color":"secondary","icon":""},on:{"click":function($event){return _vm.playRecordNocomplete(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-play-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Play Recorded Call Mark")])])]:_vm._e(),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loadingaudio,"loading":_vm.loadingaudio && _vm.currentAudio == item && !_vm.part,"color":"primary","icon":""},on:{"click":function($event){return _vm.playRecord(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-play-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Play Recorded Call")])])],_vm._v(" "+_vm._s(/* Remove record */)+" "),(_vm.admin)?[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.setRecordToRemove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]:_vm._e()],2)]}}],null,true)})]:[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.recorded,"footer-props":_vm.footerProps,"search":_vm.search,"item-key":"uuid","loading":_vm.loadingR},scopedSlots:_vm._u([{key:"item.duration",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.prettyTime(item.duration)))])]}},{key:"item.createAt",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.formatDate(item.createAt)))])]}},{key:"item.from",fn:function(ref){
            var item = ref.item;
return [(item.inbound == 1 || item.inbound == true)?[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(item.to)}}},[_vm._v(" "+_vm._s(item.to)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(item.to)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Phone")])])],1)])]:[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(item.from)}}},[_vm._v(" "+_vm._s(item.from)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(item.from)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Phone")])])],1)])]]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.employee ? item.employee.fullname : "-")+" ")]}},{key:"item.origin",fn:function(ref){
            var item = ref.item;
return [(item.inbound == 1 || item.inbound == true)?[_vm._v(_vm._s(_vm.from))]:[_vm._v(" Employee ")]]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(/* Play record */)+" "),_c('div',{staticClass:"d-flex justify-end"},[(item.timeMarcInit && item.timeMarcEnd)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loadingaudio,"loading":_vm.loadingaudio && _vm.currentAudio == item && _vm.part,"color":"secondary","icon":""},on:{"click":function($event){return _vm.playRecordNocomplete(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-play-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Play Recorded Call Mark")])])]:_vm._e(),(item.recordUrl && item.duration != 0)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loadingaudio,"loading":_vm.loadingaudio && _vm.currentAudio == item && !_vm.part,"color":"primary","icon":""},on:{"click":function($event){return _vm.playRecord(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-play-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Play Recorded Call")])])]:_vm._e(),_vm._v(" "+_vm._s(/* Remove record */)+" "),(_vm.admin)?[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.setRecordToRemove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]:_vm._e()],2)]}}],null,true)})],(_vm.from != 'Lead')?[_c('all-images',{attrs:{"uuid":_vm.uuid,"imagesProps":_vm.pictures,"isConsent":true,"imagesfrom":_vm.from == 'Lead' ? 'lead' : 'patient'},on:{"uploaded":_vm.upDateView}})]:_vm._e(),_vm._v(" "+_vm._s(/* Confirm remove */)+" "),_c('v-dialog',{attrs:{"max-width":"280","content-class":"rounded-xl","persistent":_vm.loadingR},model:{value:(_vm.dialogConfirmRemove),callback:function ($$v) {_vm.dialogConfirmRemove=$$v},expression:"dialogConfirmRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" "+_vm._s(_vm.$t("dialogTitle"))+" ")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v(" Are you sure ? ")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loadingR,"text":""},on:{"click":_vm.cancelRemove}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loadingR,"text":""},on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.$t("labelRemove"))+" ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }